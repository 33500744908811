<template>
  <div style="height: 100%" class="sss">
    <iframe
      :src="src"
      title=""
      v-if="isGuzi"
      class="guziIframe"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "bpm",
  data() {
    return {
      src: '',
      isGuzi: false,
    }
  },
  mounted() {
    const query = this.$route.query;
    let name = "";
    let type = query.approvalType;
    console.log("query>>>>>", this.$route);
    switch (type) {
      case "1":
        name = "conInfo";
        break;
      case "2":
        name = "agreeInfo";
        break;
      case "3":
      case "5":
        name = "changeInfo";
        break;
      case "4":
        let url = this.$settings.CAPITAL_URL()
        this.src = url + '' + this.$route.fullPath.slice(4)
        // if("shcms.wu-capital.com" === location.host){
        //   this.src = 'https://invest.wu-capital.com/#/projectApproval'+this.$route.fullPath.slice(4)
        // }else if("cms-uat.wu-capital.com" === location.host){
        //   this.src = 'https://invest-uat-xjp.wu-capital.com/#/projectApproval'+this.$route.fullPath.slice(4)
        // }       
        this.isGuzi = true
        break;
    }
    if("" != name) {
      const instanceId = query.instanceId;
      const Token = query.CASTGC;

      this.$router.push({ 
        name: name,
        params: { 
          id: instanceId, 
          Token: Token 
          } 
      })
    }
  },
  created() {
     
  }
};
</script>
<style lang="less" scoped>
.guziIframe {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #ccc;
}
</style>